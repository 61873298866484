import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@/hooks/useAuth';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import FormSelect from '@/components/molecules/FormSelect';
import { ListGridViewToggle } from '@/components/molecules/ListGridViewToggle';
import { SearchBar } from '@/components/molecules/SearchBar/SearchBar';
import PageHeader from '@/components/organisms/PageHeader';
import { useRecentSearch } from '@/hooks/useRecentSearch';
import useWindowScrolledFromTop from '@/hooks/useWindowScrolled';
import { SORT_BY_OPTIONS } from '@/lib/constants';
import {
  FilterChip,
  FilterType,
  UpdateFilterActionFields,
} from '@/types/filters';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { FetchProductsResult } from '@/lib/schema/inventory/types';

interface InventoryPageHeaderProps {
  autoCompleteResults: {
    searchMakes: string[];
    searchModels: Record<string, string[]>;
  };
  updateFilter: (body: UpdateFilterActionFields) => void;
  h1Tag: string;
  setIsGridView: (isGridView: boolean) => void;
  isGridView: boolean;
  onSortChange: (sort: string) => void;
  sort: string;
  data?: FetchProductsResult | null;
  filters: FilterType;
  isML: boolean;
  isDesktop: boolean;
  isClearingSearchInput: boolean;
  setIsClearingSearchInput: (isClearingSearchInput: boolean) => void;
  setPanelOpen: (isOpen: boolean) => void;
  filterChipsList: FilterChip[];
}

export const InventoryPageHeader: React.FC<InventoryPageHeaderProps> = ({
  autoCompleteResults,
  updateFilter,
  h1Tag,
  setIsGridView,
  isGridView = true,
  onSortChange,
  data,
  sort,
  filters,
  isML,
  isDesktop,
  isClearingSearchInput,
  setIsClearingSearchInput,
  setPanelOpen,
  filterChipsList,
}) => {
  const [searchInput, setSearchInput] = useState<string>(filters.vector_search);
  const [pageHeaderStyle, setPageHeaderStyle] = useState('');
  const scrolledFromTop = useWindowScrolledFromTop();
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);
  const searchBarFeatureFlag =
    process.env.NEXT_PUBLIC_SHOW_SEARCH_BAR === 'true';
  const { handleSaveRecentSearch, handleGetRecentSearch } = useRecentSearch();
  const { user } = useAuth();

  useEffect(() => {
    if (scrolledFromTop) {
      setPageHeaderStyle('ml:fadeOut');
      setTimeout(() => setPageHeaderStyle('ml:hidden'), 300);
    } else {
      setPageHeaderStyle('ml:fadeIn');
    }
  }, [scrolledFromTop]);

  const { data: recentSearch = [] } = useQuery({
    queryKey: ['fetchRecentSearch', filters.vector_search, user],
    queryFn: handleGetRecentSearch,
    throwOnError: false,
    refetchOnWindowFocus: false,
  });

  return (
    <section className="relative flex w-full flex-col gap-s">
      <PageHeader
        label={h1Tag}
        hideActions
        className={`${searchBarFeatureFlag ? pageHeaderStyle : 'ml:hidden'} hidden ml:flex`}
      />
      <section className="flex">
        <section className="flex basis-full gap-xl">
          {searchBarFeatureFlag ? (
            (isML || isDesktop) && (
              <SearchBar
                input={searchInput}
                setInput={setSearchInput}
                models={autoCompleteResults.searchModels}
                makes={autoCompleteResults.searchMakes}
                handleVectorSearch={async () => {
                  await handleSaveRecentSearch(searchInput);
                  updateFilter({
                    fields: {
                      vector_search: searchInput,
                    },
                  });
                }}
                filters={filters}
                placeholder="Search by make or model"
                append={MagnifyingGlassIcon}
                isClearingSearchInput={isClearingSearchInput}
                setIsClearingSearchInput={setIsClearingSearchInput}
                recentSearch={recentSearch}
              />
            )
          ) : (
            <PageHeader
              label={h1Tag}
              hideActions
              className="hidden self-end whitespace-nowrap ml:flex l:self-center"
            />
          )}
          <div className="flex w-full flex-col gap-l ml:hidden">
            <div className="flex w-full items-center justify-between gap-l">
              <div
                className="flex items-center gap-s"
                onClick={() => setPanelOpen(true)}
              >
                <AdjustmentsHorizontalIcon className="h-xl w-xl" />
                <span className="whitespace-nowrap text-body1Regular">
                  Filters
                </span>

                {filterChipsList.length > 0 && (
                  <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
                    {filterChipsList.length}
                  </div>
                )}
              </div>

              <div className={`flex items-center justify-center gap-s py-m`}>
                <EVSavingsToggle label="EV Savings" />
              </div>
            </div>
            {isShowSearchBar && (
              <SearchBar
                input={searchInput}
                setInput={setSearchInput}
                models={autoCompleteResults.searchModels}
                makes={autoCompleteResults.searchMakes}
                handleVectorSearch={async () => {
                  await handleSaveRecentSearch(searchInput);
                  updateFilter({
                    fields: {
                      vector_search: searchInput,
                    },
                  });
                  setIsShowSearchBar(false);
                }}
                filters={filters}
                placeholder="Search by make or model"
                isClearingSearchInput={isClearingSearchInput}
                setIsClearingSearchInput={setIsClearingSearchInput}
                recentSearch={recentSearch}
              />
            )}
          </div>

          <div className="hidden w-full justify-end gap-xl ml:flex">
            <div className="hidden items-center justify-end m:flex">
              <EVSavingsToggle label="EV Savings" />
            </div>
            <div className="hidden items-center gap-s l:flex">
              <div className="text-nuetral-900 text-body1Light">View</div>
              <ListGridViewToggle
                gridViewEnabled={isGridView}
                setIsGridView={setIsGridView}
              />
            </div>
            <FormSelect
              className="basis-1/3 ml:block"
              variant={DropdownMenuItemVariants.SingleSelect}
              disabled={data?.total === 0}
              value={[sort]}
              onChange={([value]) => onSortChange(value)}
              flexDirection={'col'}
              options={SORT_BY_OPTIONS}
            />
          </div>
        </section>
      </section>
    </section>
  );
};
